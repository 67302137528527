import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["filename", "input", "deleteButton"]
	static classes = ["hidden"]

	update({ target }) {
		this.filenameTarget.innerText = target.files[0].name

		this.deleteButtonTarget.classList.remove(this.hiddenClass)
	}

	remove() {
		this.filenameTarget.innerText = null

		this.deleteButtonTarget.classList.add(this.hiddenClass)
	}
}
